import styled from "styled-components"

const Root = styled.textarea`
    outline: none;
    width: 100%;
    font-size: 1rem;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 1px solid #b4b4b4;
`;

export default Root;
