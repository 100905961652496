import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroText from "../components/heroText"
import ContactForm from "../components/forms/contactForm"
import TextGroup from "../components/ui/textGroup"
import styled from "styled-components"
import { Mail, Phone } from "react-feather"

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
  }

  a {
    color: #222222;

    :hover{
      color: #000000;
    }
  }
`;

const ReferralsPage = () => (
  <Layout>
    <SEO title="Refer a Friend" />
    <HeroText
      statement="Referrals"
      caption="Do you know someone who could benefit from Beyond Attendance?"
    ></HeroText>

    <TextGroup
      heading="Let Us Know"
      text={<><p>If you know of someone who is in need of a biometric Time and Attendance or Access Control solution, please reach out to us by entering your details and we will be in touch.</p>
        <ContactContainer>
          <div><Mail size="1rem" color="#d34400" />&nbsp;<a href="mailto:sales@beyondattendance.com">sales@beyondattendance.com</a></div>
          <div><Mail size="1rem" color="#d34400" />&nbsp;<a href="mailto:support@beyondattendance.com">support@beyondattendance.com</a><br /></div>
          <div><Phone size="1rem" color="#d34400" />&nbsp;<a href="tel:+27317648550">+27 (0)31 764 8550</a></div>
        </ContactContainer>
      </>}>
      <ContactForm subject="referrals" />
    </TextGroup>

  </Layout>
)

export default ReferralsPage
