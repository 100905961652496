import React from "react"
import styled from "styled-components"

const Root = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 4rem 0;

  @media (max-width: 1100px) {
    margin: 1rem 0;
    }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  display: flex;
  flex-direction: row;
  padding: 0 3rem;

    @media (max-width: 1100px) {
        flex-direction: column;
        padding: 0 1rem;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    
    @media (min-width: 1100px) {
        margin: 0 0 0 1rem;
        flex: 50%;
    }
`;

const ContentContainer = styled.div`
    @media (min-width: 1100px) {
        margin: 0 1rem 0 0;
        flex: 50%;
    }
`

const Heading = styled.h2`
    font-size: 2.5rem;
    color: #18232e;
    margin: 2rem 0 0;
`;

const TextGroup = ({ children, heading, text }) => {
    return (
        <Root>
            <Container>
                <ContentContainer>
                    <Heading>{heading}</Heading>
                    {text}
                </ContentContainer>
                <ImageContainer>
                    {children}
                </ImageContainer>
            </Container>
        </Root>
    )
}

export default TextGroup
