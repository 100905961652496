import React from "react"
import styled from "styled-components"

const Root = styled.section`
   background-color: #234161;
    background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%232c2361' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
`

const Caption = styled.h2`
    color: #dbdbdb;
    font-size: 1.3rem;
    margin: 1rem 0;
    font-weight: 300;
    line-height: 1.8rem;
    text-align: center;
`;

const Statement = styled.h1`
    color: #fff;
    font-size: 3rem;
    margin: 1rem 0;
    line-height: 3rem;
    text-align: center;

    @media (max-width: 500px) {
        font-size: 2rem;
    }
`

const HeroText = ({ statement, caption }) => {
    return (
        <Root>
            <Container>
                <Statement>{statement}</Statement>
                <Caption>{caption}</Caption>
            </Container>
        </Root>
    )
}

export default HeroText
